import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'
import Footer from '../components/Footer'
import { colors } from '../../tailwind'
// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import { Title, TitleWhite, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'
import battleSimulatorSiteImage from '../images/meepdevsbattlesimulator.png'
import myBlogSiteImage from '../images/MyBlog.png'
import dasithImage from '../images/dasith.jpg'
import ravensoftImage from '../images/Ravensoft.png'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8 sm: mt-2`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 shadow-lg h-auto xl: w-48 sm: w-24`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-base md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

// eslint-disable-next-line react/prop-types
const Index = ({ location }) => (
  <>
    <Layout location={location} />
    <Parallax pages={5} scrolling>
      <Hero>
        <BigTitle>
          Hello, <br /> I'm Dasith
        </BigTitle>
        <Subtitle>I like to create cool webapps that run on highly scalable cloud infrastructure.</Subtitle>
      </Hero>
      <Projects>
        <Title>My personal projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Meep-devs Battle Simulator"
            link="http://www.meepdevs.org/BattleSimulator.aspx"
            bg={colors['grey-lightest']}
            imageLink={battleSimulatorSiteImage}
          >
            <p>
              This is a web app I created 8 years ago, during my time at college. This is a reverse engineered battle
              simulator for
              <a href="https://play.google.com/store/apps/details?id=com.kabam.doamobile&hl=en_US">
                {' '}
                Kabam - Dragons of Atlantis{' '}
              </a>
              .(This discontinued)
            </p>
          </ProjectCard>
          <ProjectCard title="My Blog" link="/" bg={colors['grey-lightest']} imageLink={myBlogSiteImage}>
            <p>
              This is my personal blog / website, created by me using gatsby.This hosts some of my articles and my work.
            </p>
          </ProjectCard>
          <ProjectCard
            title="Ravensoft"
            link="https://www.ravensoft.tech/"
            bg={colors['grey-lightest']}
            imageLink={ravensoftImage}
          >
            <p>
              This my latest startup for providing websites as a service. Reach out to me there if you are intrested in
              it.
            </p>
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About>
        <TitleWhite>About</TitleWhite>
        <AboutHero>
          <Avatar src={dasithImage} alt="Dasith Kuruppu" />
          <AboutSub>
            I'm a Software Engineer from Sri Lanka with over 8 years of industry experience. I currently work as a
            Senior Technical Lead at 99x. I like to develop & deploy websites similar to this using technologies like
            <a href="https://reactjs.org/">React</a> , <a href="https://nodejs.org/en/">Nodejs</a> &
            <a href="https://aws.amazon.com/"> Amazon Web Services </a> (AWS).
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          I am also an open source contributor and like to give back to the community whenever possible. You can find me
          on <a href="https://github.com/DasithKuruppu"> Github </a> &{' '}
          <a href="https://gitlab.com/DasithKuruppu"> GitLab </a> to check out my contributions / projects and on
          <a href="https://www.linkedin.com/in/dasith-kuruppu-aab848b8/"> LinkedIn </a> if you want to get in touch.
        </AboutDesc>
      </About>
      <Contact>
        <Footer />
      </Contact>
    </Parallax>
  </>
)

export default Index
