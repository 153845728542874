import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "tailwind.macro";

const Wrapper = styled.a`
  width: 100%;
`;
const ParentCardWrap = styled.div`
  width: 100%;
  ${tw`shadow-lg relative no-underline rounded-lg px-8 py-2 text-black`};
  background: ${props => props.bg};
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: translateY(-5px);
  }
`;

const Text = styled.div`
  ${tw`opacity-75 font-sans text-sm md:text-base py-2`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
  ${tw`text-black text-2xl md:text-3xl xl:text-4xl tracking-wide font-sans pt-8`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const ImageWrap = styled.img`
  width: 100%;
`;

const ProjectCard = ({ title, link, imageLink, children, bg }) => (
  <ParentCardWrap>
    <Wrapper href={link} target="_blank" rel="noopener noreferrer" bg={bg}>
      <Title>{title}</Title>
      {imageLink && <ImageWrap src={imageLink} />}
    </Wrapper>
    <Text>{children}</Text>
  </ParentCardWrap>
);

export default ProjectCard;

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageLink: PropTypes.string,
  link: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  bg: PropTypes.string.isRequired
};
